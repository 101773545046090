import React, { Component } from 'react';
import ReactDOM from "react-dom";
import logo from './logo.svg';
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Redtail from "./redtail"
import Salesforce from "./salesforce"

class App extends React.Component {    

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <Router>
                        <Switch>
                            <Route exact path="/RequestProcessor">
                                <Redtail />
                            </Route>
                            <Route exact path="/home">
                                <Redtail />
                            </Route>
                            <Route exact path="/PostRequestProcessor">
                                <Salesforce />
                            </Route>
                        </Switch>              
                    </Router >
                </header>
            </div>
            
        );
    }
}

export default App;